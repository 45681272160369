<template>
  <div class="page-wrap">
    <div class="top-bar">
      <mtop />
    </div>
    <div class="banner">
      <div class="inner">
        <div class="left">
          <div class="title">语音智控</div>
          <div class="desc">利用ASR、TTS、NLP及智能知识库等技术手段，通过智控引擎进行语音指令解析，并根据业务需要对接业务应用、硬件设备、业务流程及业务数据，完成指令操作。实现智控万物解放双手，构建智能一体化的语音指令交互系统。</div>
          <div class="btn" @click="dialogVisible = true, clickMenu('免费试用')">免费试用</div>
        </div>
      </div>
    </div>

    <!-- 产品功能 -->
    <div class="section bg-white func">
      <div class="title">产品功能</div>
      <div class="content">
        <div class="list">
          <div class="item">
            <div class="text-info" style="width: 466px;">
              <div class="title">可视化指令配置</div>
              <div class="desc">具备可视化指令配置界面，追求操作简单高效，快速完成指令配置，赋能各应用场景。同时支持RPA配置，用户仅需简单点击完成指令配置，极大程度简化指令配置的难度。</div>
            </div>
            <img src="@/assets/ai-voice-ctrl/img-fun-1.png" style="width: 600px;height: 426px" />
          </div>
          <div class="item">
            <img src="@/assets/ai-voice-ctrl/img-fun-2.png" style="width: 615px;height: 379px" />
            <div class="text-info" style="width: 466px;">
              <div class="title">数字人完美接入</div>
              <div class="desc">采用先进的人工智能技术及大模型能力接入，结合场景定制AI虚拟形象，充分模拟人与人之间真实的对话交互，用趋近于真人体验的可视化服务，提供有质量又温情的服务体验。</div>
            </div>
          </div>
          <div class="item">
            <div class="text-info" style="width: 417px;">
              <div class="title">多种交互指令类型</div>
              <div class="desc">可通过语音完美实现软件智控、文件调阅、预警播报、智能问答交互、数据查询、音视频播放、硬件交互控制等多种交互类型，仅需通过语音实现多种复杂指令的操作。</div>
            </div>
            <img src="@/assets/ai-voice-ctrl/img-fun-3.png" style="width: 716px;height: 259px" />
          </div>
          <div class="item">
            <img src="@/assets/ai-voice-ctrl/img-fun-4.png" style="width: 525px;height: 403px" />
            <div class="text-info" style="width: 466px;">
              <div class="title">多产品形态</div>
              <div class="desc">提供语音智控软硬一体机产品，快速完成服务部署及上线运行；同时支持具备离线语音识别能力的轻量化善控魔盒，实现即插即用无需复杂的部署流程。</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 产品优势 -->
    <div class="section advantage">
      <div class="title">产品优势</div>
      <div class="content">
        <div class="list">
          <div class="item" v-for="(item, index) in advantageList" :key="index">
            <div class="icon">
              <img :src="getIcon(item)" alt="" />
            </div>
            <div class="info">
              <div class="title">{{item.name}}</div>
              <div class="desc">{{item.content}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 应用场景 -->
    <div class="section bg-white scene">
      <div class="title">应用场景</div>
      <div class="content">
        <div class="scene-list">
          <div
              class="item"
              :class="{'border-top': index >=3 && index <= 5, 'border-r': index != 2 && index != 5}"
              v-for="(item, index) in sceneList"
              :key="index"
          >
            <div class="icon">
              <img :src="getIcon(item)" alt="" />
            </div>
            <div class="info">
              <div class="title">{{item.name}}</div>
              <div class="desc">{{item.content}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 客户案例 -->
    <div class="section case">
      <div class="title">客户案例</div>
      <div class="content">
        <div class="bg-case"></div>
      </div>
    </div>
    <!-- 合作伙伴 -->
    <partner/>

    <fotterBanner></fotterBanner>
    <mbottom />

    <demonstrate
        :dialogVisible="dialogVisible"
        @closeDialog="dialogVisible = false"
    ></demonstrate>
  </div>
</template>

<script>
import mtop from "@/components/top.vue";
import Partner from "@/components/partner";
import mbottom from "@/components/bottom.vue";
import fotterBanner from "@/components/fotterBanner.vue";
import demonstrate from "@/components/demonstrate.vue";
import {staClickMenu} from "@/utils";
export default {
  components: {
    Partner,
    mtop,
    mbottom,
    fotterBanner,
    demonstrate
  },
  data() {
    return {
      dialogVisible: false,
      advantageList: [
        {
          name: '部署方式及国产化适配',
          content: '基于多年的行业项目经验，提供内置的通用语音指令，同时可根据项目需求快速部署定制化的指令类型，支持国产化应用及服务的适配。',
          icon: 'icon-adv-1.png'
        },
        {
          name: '智能语音技术',
          content: '引擎技术路线演进，语音识别和语音合成效果大幅提升，带来业界领先的转写准确率、高流畅度的语音合成体验。',
          icon: 'icon-adv-2.png'
        },
        {
          name: '高可拓展性',
          content: '能与各种种类的硬件终端和软件系统进行深度的全方位对接，高效协调，完成指令类型丰富的执行与精准的识别工作。',
          icon: 'icon-adv-3.png'
        },
        {
          name: '大模型体验升级',
          content: '支持接入数据安全性更高的达摩私有化大模型以及通用能力更强的各公有云大模型，实现泛知识问答语音智能交互。',
          icon: 'icon-adv-4.png'
        }
      ],
      sceneList: [
        {
          name: '企业级领导驾驶舱',
          content: '通过语音指令进行领导驾驶舱的智能交互',
          icon: 'icon-scene-1.png'
        },
        {
          name: '工业领域智控',
          content: '支持对工业领域的设备进行遥控及监控作用',
          icon: 'icon-scene-2.png'
        },
        {
          name: '指挥调度中心',
          content: '基于指控中心、调度中心复杂场景调度。 实现语音控制',
          icon: 'icon-scene-3.png'
        },
        {
          name: '监控中心',
          content: '与监控中心视频监控平台进行对接，实现全流程语音交互',
          icon: 'icon-scene-4.png'
        },
        {
          name: '智慧展厅讲解助理',
          content: '作为展厅助手，可对于展厅的场景进行讲解、 播报等操作。',
          icon: 'icon-scene-5.png'
        },
        {
          name: '其他功能',
          content: '同时支持硬件调度、软件控制、网页跳转等功能',
          icon: 'icon-scene-6.png'
        }
      ]
    }
  },
  methods: {
    getIcon(item) {
      return require(`@/assets/ai-voice-ctrl/${item.icon}`)
    },
    clickMenu(menuName, url) {
      if (url) {
        this.$router.replace({
          path: url,
        });
      } else {
        if (menuName != '免费试用') {
          this.$toast("敬请期待");
        }
      }
      staClickMenu(menuName)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./style.scss";
</style>
